'use strict';

document.addEventListener('DOMContentLoaded', function () {
	setSideContainerWidth();
	addSurveyBtnListener();

	window.addEventListener('resize', setSideContainerWidth);
});

function setSideContainerWidth() {
	var sideContainer = document.querySelector('.side-content__inner');
	var parent = sideContainer.parentElement;
	var parentWidth = parent.offsetWidth;
	var style = parent.currentStyle || window.getComputedStyle(parent);
	var sideContainerWidth = parentWidth - parseInt(style.paddingLeft) * 2;

	sideContainer.style.width = sideContainerWidth + 'px';
}

function addSurveyBtnListener() {
	var $ = mktz_$;

	var $startSurveyBtn = $('.button.is-primary');
	var surveyId = '54146';

	$startSurveyBtn.on('click', tryToApplyTriggeredExperiment)

	function tryToApplyTriggeredExperiment() {
		var cookieSurvey = JSON.parse(_mktz._get_cookie('mktz_survey')) || '';

		if (!cookieSurvey) {
			tryToApplySurvey(surveyId);
		} else if (cookieSurvey[surveyId].status !== 'completed') {
			tryToApplySurvey(surveyId);
		} else {
			var $popup = $('.survey-popup');

			if ($popup.length > 0) {
				$popup.show();
			} else {
				$popup = getPopupMarkup();
				var $popupCloseBtn = $popup.find('.survey-popup__close');

				$popupCloseBtn.on('click', function () {
					$popup.hide();
				});

				$('body').prepend($popup);
			}
		}
	}

	function tryToApplySurvey(survey) {
		var experiment = _mktz.experiments[survey];

		if (!validSurvey(experiment)) {
			return;
		}

		_mktz.applyExperiment(experiment.id);
	}

	function validSurvey(experiment) {
		if (!experiment) {
			return false;
		}

		if (experiment.type != 'survey') {
			return false;
		}

		if (!_mktz._check_frequency(experiment)) {
			return false;
		}

		return true;
	}

	function getPopupMarkup() {
		return $('' +
		'<div class="survey-popup">Îți mulțumim că ne-ai răspuns deja la întrebări.' +
		'	<div class="survey-popup__close"></div>' +
		'</div>');
	}
}
